/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 承認ステータス
 */
export type PostDetailApprovalApprovalStatus = typeof PostDetailApprovalApprovalStatus[keyof typeof PostDetailApprovalApprovalStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostDetailApprovalApprovalStatus = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const;
